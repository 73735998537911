import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import CeroPopUp from "../../../../components/CeroPopUp";
import CeroButton from "../../../../components/CeroButton";
import CeroInput from "../../../../components/CeroInput";
import { useDispatch, useSelector } from "react-redux";
import { getMaterialityQuestions } from "../../../../redux/actions";
import CeroTable from "../../../../components/CeroTable";
import AddQuestions from "./AddQuestions";
import { getSupplierQuestions } from "../../../../redux/actions/supplierScreening";

const ViewQuestions = ({
  openQuestionsForm,
  setOpenQuestionsForm,
  createQuestionStatus,
  companyName,
  currentIndustry,
}) => {
  const classes = useStyles();
  const [selectedValues, setSelectedValues] = useState({});
  const dispatch = useDispatch();
  const industry = currentIndustry
  const supplierQuestionsList = useSelector((state) => {
    const data = state.supplierScreening.getSupplierQuestions.data;
    return Array.isArray(data) ? data : [];
  });

  const tableColums = [
    {
      columnKey: "topic",
      columnId: "topic",
      columnHeader: "Topic",
      width: "30%",
    },
    {
      columnKey: "relevance",
      columnId: "relevance",
      columnHeader: `The relevance of the topic for you as a ${companyName} stakeholder`,
      width: "35%",
    },
    {
      columnKey: "impact",
      columnId: "impact",
      columnHeader: `Impact/Influence of ${companyName} on this topic`,
      width: "35%",
    },
  ];

  const handleCheckboxChange = (event, questionId) => {
    const value = event.target.value;
    setSelectedValues((prevValues) => {
      const questionValues = prevValues[questionId] || [];
      if (questionValues.includes(value)) {
        return {
          ...prevValues,
          [questionId]: questionValues.filter((item) => item !== value),
        };
      } else {
        return {
          ...prevValues,
          [questionId]: [...questionValues, value],
        };
      }
    });
  };

  useEffect(() => {
    industry && dispatch(getSupplierQuestions(industry));
  }, [dispatch, industry]);

  return (
    <Paper className={classes.container}>
      <Box
        mb={4}
        display="flex"
        justifyContent="space-between"
        paddingX={4}
        paddingY={2}
      >
        <Typography fontSize={20} fontWeight={550} variant="h3">
          SUPPLIER ASSESSMENT INDUSTRY SPECIFIC QUESTIONNAIRE
        </Typography>
        <CeroButton
          buttonText="Add new Questions"
          className={classes.buttonPrimary}
          onClick={() => setOpenQuestionsForm(true)}
        />
      </Box>

      {supplierQuestionsList?.map((question, index) => (
        <Grid
          key={question.id}
          className={
            question.question_type === "text" ||
            question.question_type === "Text Field"
              ? classes.inputGrid
              : classes.gridItem
          }
        >
          <Typography
            fontSize={18}
            fontWeight={500}
            variant="h3"
            paddingLeft={4}
            paddingBottom={2}
          >
            {index + 1}. {question.question}
          </Typography>
          <Box display="flex" flexDirection="column" paddingLeft={6}>
            {(question.question_type === "choose_from_option" ||
              question.question_type === "choose_from_topic" ||
              question.question_type === "Choose from Options") &&
              question.options.map((option, idx) => (
                <FormControlLabel
                  key={idx}
                  control={
                    <Checkbox
                      className={classes.selectCheckbox}
                      checked={
                        selectedValues[question.id]?.includes(option) || false
                      }
                      onChange={(e) => handleCheckboxChange(e, question.id)}
                      value={option}
                      sx={{
                        color: "#5A404E !important",
                        borderWidth: "none",
                        "&.Mui-checked": {
                          color: "#8C2148 !important",
                        },
                      }}
                      disabled
                    />
                  }
                  label={option}
                  className={classes.label}
                />
              ))}
            {(question.question_type === "text" ||
              question.question_type === "Text Field") && (
              <Box>
                <CeroInput
                  className={classes.textAreaContainer}
                  rows={3}
                  placeholder={"Please type your Answer"}
                  id={`answer-${question.id}`}
                  name={`answer-${question.id}`}
                  label="Please type your Answer"
                  multiline
                  disabled
                />
              </Box>
            )}
            {question.question_type === "table" && (
              <Grid>
                <Typography
                  fontSize={18}
                  fontWeight={500}
                  variant="h3"
                  paddingLeft={4}
                  paddingY={3}
                >
                  Please rate the importance of the following topics for you in
                  two main areas:
                </Typography>
                <Typography paddingLeft={6} paddingBottom={2} mt={3}>
                  a) The relevance of the topic for you as a {companyName}{" "}
                  stakeholder
                </Typography>
                <Typography paddingLeft={6} paddingBottom={2} mb={3}>
                  b) The influence of {companyName} on this topic
                </Typography>
                <Typography paddingLeft={4} paddingY={3}>
                  This will allow us to identify material topics relevant to our
                  activities and disclose information about our activities
                  within{"\n"} these topics.Please use a scale from 0 to 10
                </Typography>
                <Typography paddingLeft={4} paddingBottom={2}>
                  (0 - the topic is not important at all; 10 - the topic is
                  extremely important).
                </Typography>
                <CeroTable
                  columns={tableColums}
                  data={question.options.map((option) => ({
                    topic: option,
                    relevance: "",
                    impact: "",
                  }))}
                  hasMore={false}
                  loading={false}
                  classes={{
                    headerCell: classes.headerCell,
                    tableBodyCell: classes.tableBodyCell,
                    tableHead: classes.tableTitle,
                    tableContainer: classes.tableContainer,
                  }}
                />
              </Grid>
            )}
          </Box>
        </Grid>
      ))}

      <CeroPopUp
        primaryPopUp={{
          open: openQuestionsForm,
          onClose: () => setOpenQuestionsForm(false),
          content: AddQuestions({ setOpenQuestionsForm, createQuestionStatus }),
          header: { title: "" },
        }}
        classes={{ dialog: classes.dialog }}
        showCloseButton={true}
      />
    </Paper>
  );
};

export default ViewQuestions;
