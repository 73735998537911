import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  Box,
  Popover,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Tooltip,
  Menu,
  TextField,
  Button,
} from "@mui/material";
import CeroButton from "../../../components/CeroButton";
import CreateTicketDrawer from "../../common/CreateTicketDrawer";
import useStyles from "./styles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { approveAuditBySusmgr, getAuditorList, resetRequestAuditData, resetUpdateAuditorStatus } from "../../../redux/actions/audit";
import { useDispatch, useSelector } from "react-redux";
import clsx from 'clsx';
import { getCookie } from "../../../services/cookie";
import CeroPopUp from "../../../components/CeroPopUp";
import { STATUS } from "../../../redux/constants";
import { useSnackbar } from "notistack";
import { generateAuditedReport } from "../../../redux/actions";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { APIEndpoints } from "../../../redux/constants";

const popoverStyle = {
  overflow: "visible",
  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
  mt: 1.5,
  "&:before": {
    content: '""',
    display: "block",
    position: "absolute",
    top: 0,
    right: 14,
    width: 10,
    height: 10,
    bgcolor: "background.paper",
    transform: "translateY(-50%) rotate(45deg)",
    zIndex: 0,
  },
};

const Header = ({
  onApplyFilter,
  selectedYear,
  isRequestAuditVisible,
  onRequestAudit,
  isLoading,
  statusId,
  showApproveButton,
  responseData,
  status,
  country
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filterYear, setYear] = useState(parseInt(selectedYear));
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedAuditorId, setSelectedAuditorId] = useState('');
  const [isApprovePopupOpen, setIsApprovePopupOpen] = useState(false);
  const [auditRequestPopup, setAuditRequestPopup] = useState(false);
  const [approveAuditConfirm, setApproveAuditConfirm] = useState(false);
  const [comment, setComment] = useState("");
  const queryParams = new URLSearchParams(window.location.search);
  const auditId = queryParams.get('id');
  const auditorList = useSelector((state) => state.audit.auditorList.data);
  const role = getCookie('role')
  const readOnlyMode = (getCookie("read_only_mode") === "disable_edit")
  const { enqueueSnackbar } = useSnackbar();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const approveData = useSelector(
    (state) => state.audit.approveAudit
  );

  const handleCheckboxClick = (auditorId) => {
    if (selectedAuditorId === auditorId) {
      setSelectedAuditorId(null);
    } else {
      setSelectedAuditorId(auditorId);
    }  };

  useEffect(() => {
    dispatch(getAuditorList());
  }, [dispatch]);

  const handleClick = () => {
    setAuditRequestPopup(false)
  };

  useEffect(() => {
    if (approveData.status === STATUS.SUCCESS) {
      dispatch(resetUpdateAuditorStatus())
      enqueueSnackbar("Approved audit successfully", {
        variant: "success",
      });
      navigate('/audit-status')
    } else if (approveData.status === STATUS.ERROR) {
      enqueueSnackbar(
        approveData.message.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
    }
  }, [approveData, dispatch, enqueueSnackbar]);

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedAuditorId(null);
  };

  const navigateBack = () => {
    navigate(-1);
  };

  const handleApproveButtonClick = () => {
    setIsApprovePopupOpen(true);
    setApproveAuditConfirm(false);
  };

  const handleCloseApprovePopup = () => {
    setIsApprovePopupOpen(false);
    setComment("");
  };

  const handleApproveCancel = () => {
    setApproveAuditConfirm(false)
  }

  const approveAudit = (comment) => {
    dispatch(approveAuditBySusmgr( auditId, comment));
    const payload = {
      year:selectedYear,
      data:responseData,
      isSustainabilityManager:true,
      auditId:statusId
    }
    dispatch(generateAuditedReport(payload))
  };

  const handleSaveComment = () => {
    approveAudit(comment); 
    setComment("");
    handleCloseApprovePopup();
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const getApproveAuditPopUp = () => {
    return (
      <>
        <TextField
          id="outlined-multiline-static"
          multiline
          rows={5}
          placeholder="Comment"
          style={{ width: "100%" }}
          value={comment}
          onChange={handleCommentChange}
          className={classes.textField}
        />
        <div className={classes.textLimit}>
          <div className={classes.noComments}>
            {comment.trim().length === 0 ? "No comments" : ""}
          </div>
          <div className={classes.maxCharacters}>
            {"Maximum 200 characters"}
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            className={classes.buttonPrimary}
            variant="contained"
            onClick={handleSaveComment}
          >
            Save
          </Button>
          <Button
            className={classes.buttonSecondary}
            variant="outlined"
            onClick={handleCloseApprovePopup}
          >
            Cancel
          </Button>
        </div>
      </>
    );
  };

  const handleCancel = () => {
    setAuditRequestPopup(false)
  }

  const getConfirmation = () => {
    return (
      <>
       <CloseIcon onClick={()=>setAuditRequestPopup(false)} className={classes.closeIcon} />
            <Typography variant="h6" className={classes.heading}>
          Confirm Qualitative Data Completion
        </Typography>
        <Typography style={{ textAlign: "center" }}>To proceed, please confirm that all qualitative data has been fully entered</Typography>
        <div className={classes.buttons}>
          <CeroButton
            className={classes.buttonPrimary}
            variant="contained"
            onClick={()=>{onRequestAudit(selectedAuditorId);setAuditRequestPopup(false)}}
            buttonText="Confirm"
          />
          <CeroButton
            className={classes.buttonSecondary}
            variant="outlined"
            onClick={() =>
              navigate(`/audit-status/qualitative-data/${statusId}`)
            }
            buttonText="Enter qualitative data"
          />
        </div>
      </>
    )
  }

  const getApproveConfirm = () => {
    return (
      <>
       <CloseIcon onClick={()=>setApproveAuditConfirm(false)} className={classes.closeIcon} />
            <Typography variant="h6" className={classes.heading}>
          Confirm Qualitative Data Completion
        </Typography>
        <Typography style={{ textAlign: "center" }}>To proceed, please confirm that all qualitative data has been fully entered</Typography>
        <div className={classes.buttons}>
          <CeroButton
            className={classes.buttonPrimary}
            variant="contained"
            onClick={handleApproveButtonClick}
            buttonText="Confirm"
          />
          <CeroButton
            className={classes.buttonSecondary}
            variant="outlined"
            onClick={() =>
              navigate(`/audit-status/qualitative-data/${statusId}`)
            }
            buttonText="Enter qualitative data"
          />
        </div>
      </>
    )
  }

  const authToken = getCookie("auth_token_admin");

  const onDownloadReport = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.get(
        `${APIEndpoints.DOWNLOAD_AUDIT_REPORT}/${auditId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      const url = response.data.blob_url;
      const link = document.createElement("a");
      link.href = url;
      link.click();
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };
  
  return (
    <Container className={classes.headerContainer}>
      <div className={classes.backContainer} onClick={navigateBack}>
        <ArrowBackIosIcon />
        Back
      </div>
      <Grid
        className={classes.filterContainer}
        container
        alignItems="center"
        justifyContent="space-between"
      >
        <Box className={classes.yearContainer}>
          <CeroButton
            buttonText="Qualitative data"
            className={classes.buttonPrimary}
            variant="variant"
            onClick={() =>
              navigate(`/audit-status/qualitative-data/${statusId}`)
            }
            disabled={readOnlyMode}
          />
        </Box>
        <Box className={classes.yearContainer}>
          {isRequestAuditVisible && (
            <>
            <Tooltip title="Select Auditor">
              <CeroButton
                aria-describedby={id}
                buttonText={isLoading ? "Loading..." : "Request audit"}
                onClick={()=> setAuditRequestPopup(true)}
                className={classes.buttonPrimary}
                disabled={isLoading || readOnlyMode}
              />
              </Tooltip>
              <Menu
              disableAutoFocusItem
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: { ...popoverStyle },
                  
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                className={classes.popover}
              >
              <Typography className={classes.popoverTitle}>Select Auditor</Typography>
                <FormGroup>
                  {auditorList.map((auditor, index) => (
                    <>
                    <FormControlLabel
                      className={classes.formControlLabel}
                      key={auditor.id.toString()}
                      value={auditor.id.toString()}
                      control={<Checkbox 
                        checked={selectedAuditorId === auditor.id.toString()}
                        onChange={() => {
                          handleCheckboxClick(auditor.id.toString());
                        }}
                        disabled={!auditor.status}                         
                        />}
                      label={
                        <div style={{ display: "flex", alignItems: "center", marginRight:30 }}>
                          <div className={classes.profileIcon}>
                            {auditor.name[0].toUpperCase()}
                          </div>
                          {auditor.name}
                        </div>
                      }                      
                    />
                    {index !== auditorList.length - 1 && <hr className={classes.separator} />}
                    </>
                  ))}
                </FormGroup>
                <div className={classes.popoverButtons}>
                    <CeroButton
                      buttonText="Assign"
                      variant="contained"
                      onClick={()=>{onRequestAudit(selectedAuditorId);setAuditRequestPopup(false)
                      }}
                      className={clsx(classes.buttonPrimary, classes.popoverButton)}
                    />
                    <CeroButton
                      buttonText="Cancel"
                      variant="outlined"
                      onClick={handleClose}
                      className={clsx(classes.buttonSecondary, classes.popoverButton)}
                    />
                </div>
              </Menu>
            </>
          )}
          {showApproveButton && status === "approved" && (role === "sustainability_manager" || role === "proxy_sustainability_manager" ) && <CeroButton
            buttonText={isLoading ? "Loading..." : "Approve Audit"}
            className={clsx(classes.button, classes.buttonPrimary)}
            onClick={()=> setApproveAuditConfirm(true)}
            disabled={readOnlyMode}
          />}
          {status === "audited" ?
          <CeroButton
          variant="outlined"
          buttonText="Download Report"
          className={classes.buttonPrimary}
          onClick={onDownloadReport}
          disabled={readOnlyMode}
        /> :
          <CeroButton
            variant="outlined"
            buttonText="Raise a ticket"
            className={classes.buttonPrimary}
            onClick={() => setIsDrawerOpen(true)}
            disabled={readOnlyMode}
          />}
        </Box>
        <CeroPopUp
        primaryPopUp={{
          open: isApprovePopupOpen,
          onClose: { handleCloseApprovePopup },
          content: getApproveAuditPopUp(),
          header: { title: "Approve Audit" },
        }}
        classes={{ dialog: classes.dialog,
        }}
      />
      <CeroPopUp
            primaryPopUp={{
              open: approveAuditConfirm,
              onClose: { handleApproveCancel },
              content: getApproveConfirm(),
              header: { title: "" },
            }}
            classes={{ dialog: classes.confirmDialog }}
          />
      </Grid>
      <CreateTicketDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        scope="audit"
        scopeId={statusId}
      />
      <CeroPopUp
            primaryPopUp={{
              open: auditRequestPopup,
              onClose: { handleCancel },
              content: getConfirmation(),
              header: { title: "" },
            }}
            classes={{ dialog: classes.confirmDialog }}
          />
    </Container>
  );
};

export default Header;
