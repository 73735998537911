import React from 'react'
import DashboardLayout from '../../../layouts/DashboardLayout';
import { Container } from '@mui/material';
import useStyles from './styles';

const SupplierTrackProgress = () => {
    const classes = useStyles();

    return (
        <DashboardLayout>
          <Container className={classes.container}>
            Track Progress
          </Container>
        </DashboardLayout>
      );
}

export default SupplierTrackProgress