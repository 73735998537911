import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    height: "100%",
    borderRadius: 8,
    "@media (min-width: 1200px)": {
      maxWidth: "none",
    },
  },
}));

export default useStyles;
