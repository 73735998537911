import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import DashboardLayout from "../../../layouts/DashboardLayout";
import CeroButton from "../../../components/CeroButton";
import { useNavigate } from "react-router-dom";
import useStyles from "./styles";
import { useSelector } from "react-redux";
import CeroPopUp from "../../../components/CeroPopUp";
import SendQuestions from "./SendQuestions";
import CeroInput from "../../../components/CeroInput";
import { getGenericQuestions } from "../../../redux/actions/supplierScreening";

const SupplierScreeningSurvey = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState({});
  const dispatch = useDispatch();
  const sendQuestionnairesStatus = useSelector(
    (state) => state.supplierScreening.getGenericQuestions.status
  );
  const generalQuestionsList = useSelector((state) => {
    const data = state.supplierScreening?.getGenericQuestions?.data;
    return Array.isArray(data) ? data : [];
  });

  useEffect(() => {
    dispatch(getGenericQuestions());
  }, [dispatch]);

  const handleCheckboxChange = (event, questionId) => {
    const value = event.target.value;
    setSelectedValues((prevValues) => {
      const questionValues = prevValues[questionId] || [];
      if (questionValues.includes(value)) {
        return {
          ...prevValues,
          [questionId]: questionValues.filter((item) => item !== value),
        };
      } else {
        return {
          ...prevValues,
          [questionId]: [...questionValues, value],
        };
      }
    });
  };

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <div className={classes.buttonContainer}>
          <CeroButton
            buttonText="Send Questionnaire to Suppliers"
            variant="contained"
            className={classes.buttonPrimary}
            onClick={() => setOpen(true)}
          />
        </div>
        <Box className={classes.box}>
          <Typography fontWeight={600}>
            SUPPLIER ASSESSMENT GENERAL QUESTIONNAIRE
          </Typography>
          {generalQuestionsList?.map((question, index) => (
            <Grid
              key={question.id}
              className={
                question.question_type === "text" ||
                question.question_type === "Text Field"
                  ? classes.inputGrid
                  : classes.gridItem
              }
            >
              <Typography
                fontSize={18}
                fontWeight={500}
                variant="h3"
                paddingLeft={4}
                paddingBottom={2}
              >
                {index + 1}. {question.question}
              </Typography>
              <Box display="flex" flexDirection="column" paddingLeft={6}>
                {(question.question_type === "choose_from_option" ||
                  question.question_type === "choose_from_topic" ||
                  question.question_type === "Choose from Options") &&
                  question.options.map((option, idx) => (
                    <FormControlLabel
                      key={idx}
                      control={
                        <Checkbox
                          className={classes.selectCheckbox}
                          checked={
                            selectedValues[question.id]?.includes(option) ||
                            false
                          }
                          onChange={(e) => handleCheckboxChange(e, question.id)}
                          value={option}
                          sx={{
                            color: "#5A404E !important",
                            borderWidth: "none",
                            "&.Mui-checked": {
                              color: "#8C2148 !important",
                            },
                          }}
                          disabled
                        />
                      }
                      label={option}
                      className={classes.label}
                    />
                  ))}
                {(question.question_type === "text" ||
                  question.question_type === "Text Field") && (
                  <Box>
                    <CeroInput
                      className={classes.textAreaContainer}
                      rows={3}
                      placeholder={"Please type your Answer"}
                      id={`answer-${question.id}`}
                      name={`answer-${question.id}`}
                      label="Please type your Answer"
                      multiline
                      disabled
                    />
                  </Box>
                )}
              </Box>
            </Grid>
          ))}
        </Box>
        <CeroPopUp
          primaryPopUp={{
            open: open,
            onClose: () => setOpen(false),
            content: SendQuestions({ setOpen, sendQuestionnairesStatus }),
            header: { title: "" },
          }}
          classes={{ dialog: classes.popUpdialog }}
          showCloseButton={true}
        />
      </Container>
    </DashboardLayout>
  );
};

export default SupplierScreeningSurvey;
