import { all, put, call, takeLatest } from 'redux-saga/effects'

import { request } from '../../services/client'
import { ActionTypes } from '../constants/actions';
import { APIEndpoints } from '../constants';

export function* getSupplierQuestions(action) {
    try {
        const { industryId } = action.payload
        const response = yield call(request, APIEndpoints.GET_SUPPLIER_QUESTIONS(industryId), {
            method: 'GET',
        })
        yield put({
            type: ActionTypes.GET_SUPPLIER_QUESTIONS_SUCCESS,
            payload: response.supplier_industry_questions
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.GET_SUPPLIER_QUESTIONS_FAILURE,
            payload: err.message
        })
    }
}

export function* createSupplierQuestions(action) {
    try {        
        const { industryId } = action.payload
        const response = yield call(request, APIEndpoints.CREATE_SUPPLIER_QUESTIONS(industryId), {
            method: 'POST',
            payload: industryId
        })
        yield put({
            type: ActionTypes.CREATE_SUPPLIER_QUESTIONS_SUCCESS,
            payload: response
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.SCREATE_SUPPLIER_QUESTIONS_FAILURE,
            payload: err.message
        })
    }
}

export function* getGenericQuestions() {
    try {
        const response = yield call(request, APIEndpoints.GET_GENERIC_QUESTIONS, {
            method: 'GET',
        })
        yield put({
            type: ActionTypes.GET_GENERIC_QUESTIONS_SUCCESS,
            payload: response.supplier_generic_questions
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.GET_GENERIC_QUESTIONS_FAILURE,
            payload: err.message
        })
    }
}

export function* sendSupplierQuestions(action) {
    try {        
        const { senderList } = action.payload
        const response = yield call(request, APIEndpoints.SEND_SUPPLIER_QUESTIONS, {
            method: 'POST',
            payload: senderList
        })
        yield put({
            type: ActionTypes.SEND_SUPPLIER_QUESTIONS_SUCCESS,
            payload: response
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.SEND_SUPPLIER_QUESTIONS_FAILURE,
            payload: err.message
        })
    }
}

export default function* root() {
    yield all([
        takeLatest(ActionTypes.GET_SUPPLIER_QUESTIONS, getSupplierQuestions),
        takeLatest(ActionTypes.CREATE_SUPPLIER_QUESTIONS, createSupplierQuestions),
        takeLatest(ActionTypes.GET_GENERIC_QUESTIONS, getGenericQuestions),
        takeLatest(ActionTypes.SEND_SUPPLIER_QUESTIONS, sendSupplierQuestions),
    ])
}
