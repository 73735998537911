import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    "@media (min-width: 1200px)": {
      maxWidth: "none",
    },
  },
  loader: {
    width: "100%",
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(5),
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    fontWeight: 400,
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
    width: "fit-content",
    height: 37,
    fontSize: 14,
    marginLeft: theme.spacing(2),
  },
  box: {
    backgroundColor: "white",
    padding: theme.spacing(5),
    borderRadius: 8,
  },
  popUpdialog: {
    width: "50% !important",
    textAlign: "center",
    "& .MuiPaper-root.MuiDialog-paper": {
      maxWidth: "none",
      backgroundColor: "white",
    },
  },
  gridItem: {
    margin: theme.spacing(5, 0),
  },
  inputGrid: {
    margin: theme.spacing(5, 0),
    paddingBottom: theme.spacing(8),
  },
}));

export default useStyles;
