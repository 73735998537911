import { handleActions } from "redux-actions";
import immutable from "immutability-helper";

import { ActionTypes } from "../constants/actions";
import { STATUS } from "../constants";
import { parseError } from "../../services/client";

export const supplierScreeningState = {
  getSupplierQuestions: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  createSupplierQuestions: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  getGenericQuestions: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  sendSupplierQuestions: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
};

const supplierScreeningActions = {
  supplierScreening: handleActions(
    {
      [ActionTypes.GET_SUPPLIER_QUESTIONS]: (state, { payload }) =>
        immutable(state, {
          getSupplierQuestions: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_SUPPLIER_QUESTIONS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getSupplierQuestions: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_SUPPLIER_QUESTIONS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getSupplierQuestions: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.CREATE_SUPPLIER_QUESTIONS]: (state, { payload }) =>
        immutable(state, {
          createSupplierQuestions: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.CREATE_SUPPLIER_QUESTIONS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          createSupplierQuestions: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.CREATE_SUPPLIER_QUESTIONS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          createSupplierQuestions: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_GENERIC_QUESTIONS]: (state, { payload }) =>
        immutable(state, {
          getGenericQuestions: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_GENERIC_QUESTIONS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getGenericQuestions: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_GENERIC_QUESTIONS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getGenericQuestions: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.SEND_SUPPLIER_QUESTIONS]: (state, { payload }) =>
        immutable(state, {
          sendSupplierQuestions: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.SEND_SUPPLIER_QUESTIONS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          sendSupplierQuestions: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.SEND_SUPPLIER_QUESTIONS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          sendSupplierQuestions: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.RESET_SUPPLIER_SCREENING_STATUS]: (state, { payload }) =>
        immutable(state, {
          getSupplierQuestions: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
          },
        }),
    },
    supplierScreeningState
  ),
};

export default supplierScreeningActions;
