import { createActions } from 'redux-actions';
import { ActionTypes } from '../constants/actions';

export const {
    getSupplierQuestions,
    createSupplierQuestions,
    getGenericQuestions,
    sendSupplierQuestions
} = createActions({
    [ActionTypes.GET_SUPPLIER_QUESTIONS]: (industryId) => ({industryId}),
    [ActionTypes.CREATE_SUPPLIER_QUESTIONS]: (industryId) => ({industryId}),
    [ActionTypes.GET_GENERIC_QUESTIONS]: () => ({}),
    [ActionTypes.SEND_SUPPLIER_QUESTIONS]: (senderList) => ({senderList}),
})
